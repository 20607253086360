<template>
  <v-container id="productRequest" fluid tag="section">
    <v-data-table
      :headers="columns"
      :items="productRequest"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.user_data[0].first_name }} {{ item.user_data[0].last_name }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.user_data[0].email }}
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Product Request',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      moduleName: "ProductRequest",
      modulePermission: [],
      search: "",
      page: 1,
      productRequest: [],
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
    };
  },
  computed: {
    columns() {
      return [
        // {
        //   text: "Actions",
        //   value: "actions",
        //   fixed: true,
        //   sortable: false,
        //   width: "150px",
        // },
        {
          sortable: false,
          text: "Id",
          value: "_id",
          width: "40px",
          sortable: false,
        },
        { sortable: false, text: "Name", value: "name", width: "110px" },
        { sortable: false, text: "Email", value: "email", width: "100px" },
        {
          sortable: false,
          text: "Product Name",
          value: "product_name",
          width: "150px",
        },
        {
          sortable: false,
          text: "Brand Name",
          value: "brand_name",
          width: "180px",
        },
      ];
    },
  },
  methods: {
    getProductRequest() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "listingProudctRequestByCustomer/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.productRequest = response.data.productRequest.data;
            this.loading = false;
            this.totalPages = response.data.productRequest.last_page;
            this.startRecord = response.data.productRequest.from;
            this.endRecord = response.data.productRequest.to;
            this.totalRecord = response.data.productRequest.total;
            this.numbers = [];
            for (
              let num = response.data.productRequest.from;
              num <= response.data.productRequest.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getProductRequest();
    },
  },
  mounted() {
    this.getProductRequest();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
  },
};
</script>
